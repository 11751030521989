@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

.main-content-transition {
@apply transition-all duration-300 ease-linear;
}

.nav-expanded {
  @apply ml-64;
}

.nav-collapsed {
  @apply ml-16;
}

.main-content {
  @apply bg-white shadow-lg rounded-lg overflow-hidden p-5;
}

.emailContent {
  word-wrap: break-word; /* Break long words to prevent overflow */
  overflow-wrap: break-word; /* Ensure wrapping at arbitrary break points if needed */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f3f0f0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --sidebar-width-collapsed: 4rem; /* Example: 64px if using REMs and base font-size is 16px */
  --sidebar-width-expanded: 16rem; /* Example: 256px */
}

/* CSS */
.hidden {
  display: none;
}
